export default {
  mounted (el, binding) {
    if (binding.value) {
      el.addEventListener('input', el.onlyNumbersHandler = (event) => {
        const value = event.target.value
        const newValue = value.replace(/\D/g, '')
        if (value !== newValue) {
          event.target.value = newValue
          event.target.dispatchEvent(new Event('input'))
        }
      })
    }
  },
  updated (el, binding) {
    if (binding.value !== binding.oldValue) {
      if (binding.value) {
        el.addEventListener('input', el.onlyNumbersHandler = (event) => {
          const value = event.target.value
          const newValue = value.replace(/\D/g, '')
          if (value !== newValue) {
            event.target.value = newValue
            event.target.dispatchEvent(new Event('input'))
          }
        })
      } else {
        el.removeEventListener('input', el.onlyNumbersHandler)
        el.onlyNumbersHandler = null
      }
    }
  },
  unmounted (el) {
    el.removeEventListener('input', el.onlyNumbersHandler)
  }
}
