<template>
  <button
    :type="type"
    :class="classes"
    :disabled="isDisabled">
    <slot/>
    <span v-if="wave" class="base-button__wave"></span>
  </button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'button',
      validator: value => ['button', 'submit', 'reset'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    wave: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled () {
      const isLoading = this.$store.getters.isLoading

      return !!(isLoading || this.disabled)
    },
    classes () {
      return {
        'base-button': true,
        'base-button--success': this.success,
        'base-button--wave': this.wave
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.base-button {
  position: relative;
  padding: 15px 20px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 700;
  border-radius: 30px;
  font-size: 20px;
  transition: 0.3s;
  word-break: break-word;

  &--success {
    background-color: #2b7a69;

    &:hover {
      background-color: #38a089;
    }
  }

  &__wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    animation: wave-fill 3s infinite ease-in-out;
    pointer-events: none;
  }

  @keyframes wave-fill {
    0% {
      width: 0;
      opacity: 0;
    }
    50% {
      width: 100%;
      opacity: 1;
    }
    100% {
      width: 100%;
      opacity: 0;
    }
  }
}
</style>
