<template>
  <header class="header">
    <div class="header__logo">
      <router-link to="/" class="header__logo-link" aria-label="ClearGo">ClearGo</router-link>
    </div>
    <nav class="header__nav">
      <ul class="header__nav__menu">
        <li class="header__nav__menu-item">
          <router-link class="header__nav__menu-link" to="#calculator">Калькулятор</router-link>
        </li>
        <li class="header__nav__menu-item">
          <router-link class="header__nav__menu-link" to="#gallery">Наши работы</router-link>
        </li>
        <li class="header__nav__menu-item">
          <router-link class="header__nav__menu-link" to="#how-do-work">Химчистка</router-link>
        </li>
        <li class="header__nav__menu-item">
          <router-link class="header__nav__menu-link" to="#services">Услуги</router-link>
        </li>
      </ul>
    </nav>
    <div class="header__contacts">
      <a href="tel:89017736226" class="header__contacts__phone">
        <font-awesome-icon :icon="faPhone" class="header__contacts__phone-icon"/>
        +7 (901) 773-62-26
      </a>
      <div class="header__socials">
        <a href="https://t.me/ClearGoRuBot" target="_blank" class="header__socials-link" aria-label="Telegram">
          <font-awesome-icon :icon="faTelegram"/>
        </a>
        <a href="https://api.whatsapp.com/send/?phone=79017736226&text=Здравствуйте%2C+у+меня+есть+вопрос"
           target="_blank" class="header__socials-link" aria-label="WhatsApp">
          <font-awesome-icon :icon="faWhatsapp"/>
        </a>
      </div>
      <base-button success wave @click="openModal('CallBack')">Перезвоните мне</base-button>
    </div>

    <div class="header__hamburger" @click="toggleMenu">
      <span class="header__hamburger-line"></span>
      <span class="header__hamburger-line"></span>
      <span class="header__hamburger-line"></span>
    </div>

  </header>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faBars, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons'
import { defineComponent } from 'vue'
import BaseButton from '@/components/common/BaseButton.vue'

export default defineComponent({
  components: { BaseButton, FontAwesomeIcon },
  name: 'AppHeader',
  inject: ['openModal', 'toggleMenu'],
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      faTelegram,
      faWhatsapp,
      faPhone,
      faBars,
      faTimes
    }
  }
})

</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 0 20px 0 20px;
  z-index: 1;

  &__logo {
    font-size: 24px;
    font-weight: 700;

    &-link {
      color: #232323;
    }
  }

  &__nav {
    display: flex;

    &__menu {
      display: flex;

      &-item {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      &-link {
        text-decoration: none;
        color: #232323;
        font-weight: 500;
        font-size: 20px;
        transition: color 0.3s ease;

        &:hover {
          color: #007bff;
        }
      }

      @media screen and (max-width: 1200px) {
        &-item {
          margin-right: 10px;
        }

        &-link {
          font-size: 18px;
        }
      }

    }
  }

  &__contacts {
    display: flex;
    align-items: center;

    &__phone {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-size: 20px;
      color: #232323;
      font-weight: bold;

      &-icon {
        color: #232323;
        margin-right: 8px;
        font-size: 20px;
      }

      @media screen and (max-width: 1200px) {
        margin-right: 5px;
        font-size: 16px;
      }
    }
  }

  &__socials {
    display: flex;
    margin-right: 20px;

    &-link {
      margin-left: 10px;
      font-size: 22px;
      transition: color 0.3s ease;

      &:first-child {
        color: #0088cc;

        &:hover {
          color: #66c6f2;
        }
      }

      &:last-child {
        color: #25d366;

        &:hover {
          color: #6fe89e;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      margin-right: 10px;
      &-link {
        margin-left: 5px;
      }
    }
  }

  &__hamburger {
    display: none;
    width: 35px;
    height: 30px;
    position: relative;
    cursor: pointer;

    &-line {
      background-color: #232323;
      position: absolute;
      border-radius: 2px;
      transition: 0.5s cubic-bezier(.8, .5, .2, 1.4);
      width: 100%;
      height: 4px;
      transition-duration: 350ms;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        top: 13px;
        left: 0;
        opacity: 1;
      }

      &:nth-child(3) {
        bottom: 0;
        left: 0;
      }
    }

    &:not(&--open):hover &-line:nth-child(1) {
      transform: rotate(0) scaleY(1);
    }

    &:not(&--open):hover &-line:nth-child(2) {
      transform: rotate(0) scaleY(1);
    }

    &:not(&--open):hover &-line:nth-child(3) {
      transform: rotate(0) scaleY(1);
    }
  }

  @media screen and (max-width: 992px) {
    &__nav {
      display: none;
    }

    &__contacts {
      display: none;
    }

    &__hamburger {
      display: inline-block;
    }
  }
}

</style>
