export default {
  beforeMount (el) {
    const formatPhoneNumber = (value) => {
      const cleaned = value.replace(/\D/g, '')
      const match = cleaned.match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)

      match[1] = '+7'

      return !match[3]
        ? `${match[1]} (${match[2]}`
        : `${match[1]} (${match[2]}) ${match[3]}${match[4] ? '-' + match[4] : ''}${match[5] ? '-' + match[5] : ''}`
    }

    const processPaste = (value) => {
      let cleaned = value.replace(/\D/g, '')

      // Если вставляют номер, начинающийся с 7 или 8
      if (cleaned.charAt(0) === '7' || cleaned.charAt(0) === '8') {
        cleaned = '+7' + cleaned.slice(1)
      }

      return formatPhoneNumber(cleaned)
    }

    el.oninput = (e) => {
      if (!e.isTrusted) return
      el.value = formatPhoneNumber(el.value)
    }

    el.onpaste = (e) => {
      e.preventDefault()
      const paste = (e.clipboardData || window.clipboardData).getData('text')
      el.value = processPaste(paste)
    }
  }
}
