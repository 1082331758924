import { createStore } from 'vuex'

export default createStore({
  state: {
    isOverlayVisible: false
  },
  getters: {
    isOverlayVisible: state => state.isOverlayVisible
  },
  actions: {
    openOverlay ({ commit }) {
      commit('SET_OVERLAY_VISIBLE', true)
    },
    closeOverlay ({ commit }) {
      commit('SET_OVERLAY_VISIBLE', false)
    },
    toggleOverlay ({ commit, getters }) {
      commit('SET_OVERLAY_VISIBLE', !getters.isOverlayVisible)
    }
  },
  mutations: {
    SET_OVERLAY_VISIBLE (state, isVisible) {
      state.isOverlayVisible = isVisible
    }
  },
  modules: {}
})
