<template>
  <div class="modal" @click.self="hide">
    <div class="modal__dialog">
      <div class="modal__content">

        <div class="modal__header">
          <h1 class="modal__title">
            <slot name="title">Default title</slot>
          </h1>

          <button
              @click="hide"
              class="modal__close">
            <font-awesome-icon :icon="faTimes"/>
          </button>
        </div>

        <div class="modal__body">
          <slot name="body">Default body</slot>
        </div>

        <div class="modal__footer">
          <slot name="footer">Default footer</slot>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'ModalLayout',
  components: { FontAwesomeIcon },
  inject: ['closeModal'],
  data () {
    return {
      faTimes
    }
  },
  mounted () {
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    handleKeydown (event) {
      if (event.key === 'Escape') {
        this.hide()
      }
    },
    hide () {
      this.closeModal()
    }
  }
})
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1055;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &--xl {
    max-width: 1140px;
  }

  &--avg {
    max-width: 770px;
  }

  &__dialog {
    position: relative;
    width: auto;
    margin: 28px auto;
    pointer-events: none;
    max-width: 500px;
    padding: 10px;

    @include sm-tablets() {
      position: absolute;
      bottom: 0;
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-radius: 10px;
    outline: 0;

    @include sm-tablets() {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
    border-bottom: 1px solid #dee2e6;
  }

  &__title {
    color: #000000;
    font-size: 21px;
    font-weight: initial;
  }

  &__close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 26px;
  }

  &__body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 20px;

    .form-group {
      label {
        display: block;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid #dee2e6;
  }
}

.modal-enter-active, .modal-leave-active {
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.modal-enter-from, .modal-leave-to {
  transform: translateY(-100%);
  opacity: 0;

  @include sm-tablets() {
    transform: translateY(100%);
  }
}

.modal-enter-to, .modal-leave-from {
  transform: translateY(0);
  opacity: 1;
}

</style>
