<template>
  <div class="field">
    <label v-if="label">{{ label }}</label>
    <input
        :type="type"
        :placeholder="placeholder"
        :value="modelValue"
        v-only-numbers="onlyNumbers"
        v-phone-formatter="phoneFormatter"
        autocomplete="off"
        @input="handleInput"
        @change="handleChange"
        required
    >
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import onlyNumbers from '@/directives/onlyNumbers'
import phoneFormatter from '@/directives/phoneFormatter'

export default defineComponent({
  name: 'BaseInput',
  directives: {
    onlyNumbers, phoneFormatter
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'password'].includes(value)
    },
    onlyNumbers: {
      type: Boolean,
      default: false
    },
    phoneFormatter: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleInput (event) {
      this.$emit('update:modelValue', event.target.value)
    },
    handleChange (event) {
      this.$emit('onChange', event.target.value)
    }
  }
})
</script>

<style lang="scss" scoped>
input {
  border: 1px solid #dee2e6;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-color: #ffffff;
  border-radius: 6px;
}

.field {
  width: 100%;

  label {
    display: block;
    margin-bottom: 5px;
  }
}
</style>
