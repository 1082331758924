<template>
  <app-header :is-menu-open="isMenuOpen"/>
  <main class="content">
    <router-view/>
  </main>
  <app-footer/>

  <transition name="modal">
    <component v-if="currentModal" :is="currentModal" :key="currentModal" @hide="closeModal"/>
  </transition>

  <app-mobile-menu :is-open="isMenuOpen" @close-menu="toggleMenu"/>

  <app-overlay/>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { defineComponent } from 'vue'
import AppOverlay from '@/components/feature/AppOverlay.vue'
import CallBack from '@/components/feature/modal/CallBack.vue'
import AppMobileMenu from '@/components/feature/AppMobileMenu.vue'

export default defineComponent({
  components: { AppMobileMenu, AppFooter, AppHeader, AppOverlay, CallBack },
  data () {
    return {
      currentModal: null,
      isMenuOpen: false
    }
  },
  methods: {
    openModal (modalName) {
      this.$store.dispatch('openOverlay')
      this.currentModal = modalName
    },
    closeModal () {
      this.currentModal = null
      this.$store.dispatch('closeOverlay')
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
      if (!this.currentModal) {
        this.$store.dispatch('toggleOverlay')
      }
    }
  },
  provide () {
    return {
      openModal: this.openModal,
      closeModal: this.closeModal,
      toggleMenu: this.toggleMenu
    }
  }
})

</script>

<style lang="scss">
#app {

}
</style>
