<template>
  <modal-layout @hide="closeModal">
    <template #title>
      Заказать обратный звонок
    </template>

    <template #body>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <base-input
              label="Ваше имя"
              type="text"
              placeholder="Александр"
              :model-value="data.name"/>
        </div>

        <div class="form-group">
          <base-input
              label="Ваш телефон"
              type="text"
              placeholder="7 (999) 999-99-99"
              :model-value="data.phone"
              :phone-formatter="true"/>
        </div>
      </form>
    </template>

    <template #footer>
      <base-button type="submit" success>Отправить</base-button>
    </template>
  </modal-layout>
</template>

<script>
import ModalLayout from '@/components/feature/modal/Layout.vue'
import BaseInput from '@/components/common/BaseInput.vue'
import BaseButton from '@/components/common/BaseButton.vue'

export default {
  name: 'CallBackModal',
  components: { BaseButton, BaseInput, ModalLayout },
  data () {
    return {
      data: {
        name: '',
        phone: ''
      }
    }
  },
  methods: {
    submitForm () {
      // Логика отправки формы
      this.closeModal()
    },
    closeModal () {
      this.$emit('hide')
    }
  }
}
</script>
